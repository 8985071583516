import { useTitle } from '@vueuse/core';
import { defineStore, acceptHMRUpdate } from 'pinia';
import api from '@/api';
import axios from '@/plugins/common/axios';
import { useStoreAppBreadcrumbsIndex } from '@/stores/app/breadcrumbs';

export const useStorePagesShow = defineStore({
    id: 'pages/show',

    state: () => ({
        loaded: false,
        pending: true,
        data: null,
    }),

    getters: {
        //
    },

    actions: {
        async fetchShow(data: any, callback: any) {
            const self = this;
            self.pending = true;
            const url = api.pages.show.replace(':id', data.id);
            await axios.get(url, {}, (result: any) => {
                self.loaded = true;
                self.pending = false;
                self.data = result.data;
                console.log(url);
                console.log(result.data);
                // useTitle(self.data.title);
                if (result.breadcrumbs !== undefined) {
                    useStoreAppBreadcrumbsIndex().create(result.breadcrumbs);
                }
                callback(self.data);
            }, () => {
                self.pending = false;
            });
        },
        setLoaded(val: boolean) {
            this.loaded = val;
        }
    },
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStorePagesShow, import.meta.hot));
}
